<template>
    <AuthComponent
      @registrationCompleteEvent="changeTab"
      v-if="currentTab == 'auth-tab'"></AuthComponent>
    <MainComponent 
      @exitEvent="changeTab"
      v-if="currentTab == 'main-tab'"></MainComponent>
    <div v-if="currentTab == 'banner'">
      <BannerComponent></BannerComponent>
    </div>
</template>

<script>
import AuthComponent from '@/components/Auth/AuthComponent.vue';
import MainComponent from '@/components/MainBlock/MainComponent.vue';
import BannerComponent from './components/MainBlock/BannerComponent.vue';
import Cookie from './assets/js/cookie.js';
export default {
  name: 'App',
  data() {
    return {
      currentTab: 'main-tab'
    }
  },
  created() {
    const cookie = new Cookie();
    if (!cookie.get('community_id') || !cookie.get('community_type') || !cookie.get('community_value')) {
      this.currentTab = 'auth-tab'
    }
    if (window.innerWidth > 500) {
      this.currentTab = 'banner'
    }
  },
  methods: {
    changeTab(data) {
      this.currentTab = data;
    }
  },
  components: {
    AuthComponent, MainComponent, BannerComponent
  }
}
</script>

<style>
@import url('/src/assets/css/index.css');
#app {
  background: url('/src/assets/img/_ (3).jpeg') no-repeat center center;
  background-size: cover;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 500px) {
  #app {
    background: none !important;
    justify-content: center;
  }
}
</style>
